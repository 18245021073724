import {
  Badge,
  Button,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconBrandAzure,
  IconBrandOpenai,
  IconCloudDownload,
  IconCurrencyDollar,
  IconKey,
  IconLock,
  IconMessageCircleSearch,
  IconNorthStar,
  IconReportSearch,
} from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";
import { LogoText, PineconeLogo } from "../components/Logo";
import { SettingsModal } from "../components/SettingsModal";
import { db } from "../db";
import { config } from "../utils/config";

export function IndexRoute() {
  const settings = useLiveQuery(() => db.settings.get("general"));
  const { openAiApiKey } = settings ?? {};

  return (
    <>
      <Center py="xl" sx={{ height: "100%" }}>
        <Container size="sm">
          <Badge mb="lg" color="yellow">Work In Progress</Badge>
          <Text>
            <LogoText style={{ maxWidth: 240 }} />
          </Text>
          <Text mt={4} size="xl">
            George's Career Voyage - A new journey into AI/ML
          </Text>
          <SimpleGrid
            mt={50}
            cols={3}
            spacing={30}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {features.map((feature) => (
              <div key={feature.title}>
                <ThemeIcon variant="outline" size={50} radius={50}>
                  <feature.icon size={26}/>
                </ThemeIcon>
                <Text mt="sm" mb={7}>
                  {feature.title}
                </Text>
                <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                  {feature.description}
                </Text>
              </div>
            ))}
          </SimpleGrid>
          <Group mt={50}>
            {config.allowSettingsModal && (
              <SettingsModal>
                <Button
                  size="md"
                  variant={openAiApiKey ? "light" : "filled"}
                  leftIcon={<IconKey size={20} />}
                >
                  {openAiApiKey ? "Change OpenAI Key" : "Enter OpenAI Key"}
                </Button>
              </SettingsModal>
            )}
            {config.showDownloadLink && !window.todesktop && (
              <Button
                component="a"
                href="https://dl.todesktop.com/230313oyppkw40a"
                // href="https://download.chatpad.ai/"
                size="md"
                variant="outline"
                leftIcon={<IconCloudDownload size={20} />}
              >
                Download Desktop App
              </Button>
            )}
          </Group>
        </Container>
      </Center>
    </>
  );
}

const features = [
  {
    icon: IconBrandOpenai,
    title: "Uses OpenAI LLM",
    description:
      "This application uses OpenAI LLM to create a Natural Language Processor for your questions around George's professional history",
  },
  {
    icon: IconMessageCircleSearch,
    title: "RAG",
    description:
      "To get the most relevant information, Retrieval Argmented Generation methods are used while querying",
  },
  {
    icon: PineconeLogo,
    title: "Pinecone Vector Store",
    description:
      "PDF version of the CV was taken, and embeddings were created and stored in a Pinecone Database",
  },
  {
    icon: IconReportSearch,
    title: "RnD",
    description:
      "Learning the craft of ML and AI",
  },
  {
    icon: IconBrandAzure,
    title: "Azure DevOps",
    description:
      "Deployed via CI/CD pipeline on Azure DevOps",
  },
];
