import { encode } from "gpt-token-utils";
import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from "openai";
import { OpenAIExt } from "openai-ext";
import { db } from "../db";
import { config } from "./config";

export interface ChatCompletionParameters {
  model?: string | undefined;
  temperature?: number;
  messages?: ChatCompletionRequestMessage[] | undefined;
}

function getClient(
  apiKey: string,
  apiType: string,
  apiAuth: string,
  basePath: string
) {
  const configuration = new Configuration({
    ...((apiType === "openai" ||
      (apiType === "custom" && apiAuth === "bearer-token")) && {
      apiKey: apiKey,
    }),
    ...(apiType === "custom" && { basePath: basePath }),
  });
  return new OpenAIApi(configuration);
}

export async function createStreamChatCompletion(
  messages: ChatCompletionRequestMessage[],
  chatId: string,
  messageId: string
) {
  const settings = await db.settings.get("general");
  const model = settings?.openAiModel ?? config.defaultModel;
  // const tokenCount = getTotalTokens(chatId)
  console.log("about to call..");
  const response = await fetch("/api/ChatCompletionStream", {
    method: "POST",
    body: JSON.stringify({
      model: model,
      temperature: 0.7, // should read this from DB..
      messages,
    } as ChatCompletionParameters),
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
  });

  const reader = response.body.getReader();

  let contentHold = "";

  while (true) {
    const { done, value } = await reader.read();
    const content = new TextDecoder("utf-8").decode(value);
    contentHold += content;
    setStreamContent(messageId, contentHold, done);
    if (done) {
      setTotalTokens(chatId, contentHold);
      break;
    }
  }
}

function setStreamContent(
  messageId: string,
  content: string,
  isFinal: boolean
) {
    console.log(isFinal);
  content = isFinal ? content : content + "█";
  db.messages.update(messageId, { content: content });
}

function setTotalTokens(chatId: string, content: string) {
  let total_tokens = encode(content).length;
  db.chats.where({ id: chatId }).modify((chat) => {
    if (chat.totalTokens) {
      chat.totalTokens += total_tokens;
    } else {
      chat.totalTokens = total_tokens;
    }
  });
}

export async function createChatCompletion(
  messages: ChatCompletionRequestMessage[]
) {
  const settings = await db.settings.get("general");
  const model = settings?.openAiModel ?? config.defaultModel;
  const type = settings?.openAiApiType ?? config.defaultType;
  const auth = settings?.openAiApiAuth ?? config.defaultAuth;
  const base = settings?.openAiApiBase ?? config.defaultBase;
  const version = settings?.openAiApiVersion ?? config.defaultVersion;


  return await fetch("/api/GetChatCompletions", {
    method: "POST",
    body: JSON.stringify({
      model: model,
      temperature: 0.7, // should read this from DB..
      messages,
    } as ChatCompletionParameters),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function checkOpenAIKey(apiKey: string) {
  return createChatCompletion([
    {
      role: "user",
      content: "hello",
    },
  ]);
}
